@media screen and (max-width: 768px){ 
	.woocommerce table.shop_table_responsive tbody th,
	.woocommerce table.shop_table_responsive thead,
	.woocommerce-page table.shop_table_responsive tbody th,
	.woocommerce-page table.shop_table_responsive thead {
	    display: none
	}

	.woocommerce table.shop_table_responsive tbody tr:first-child td:first-child,
	.woocommerce-page table.shop_table_responsive tbody tr:first-child td:first-child {
	    border-top: 0
	}

	.woocommerce table.shop_table_responsive tr,
	.woocommerce-page table.shop_table_responsive tr {
	    display: block
	}

	.woocommerce table.shop_table_responsive tr td,
	.woocommerce-page table.shop_table_responsive tr td {
	    display: block;
	    text-align: right!important
	}

	.woocommerce #content table.cart .product-thumbnail,
	.woocommerce table.cart .product-thumbnail,
	.woocommerce table.my_account_orders tr td.order-actions:before,
	.woocommerce table.shop_table_responsive tr td.actions:before,
	.woocommerce table.shop_table_responsive tr td.product-remove:before,
	.woocommerce-page #content table.cart .product-thumbnail,
	.woocommerce-page table.cart .product-thumbnail,
	.woocommerce-page table.my_account_orders tr td.order-actions:before,
	.woocommerce-page table.shop_table_responsive tr td.actions:before,
	.woocommerce-page table.shop_table_responsive tr td.product-remove:before {
	    display: none
	}

	.woocommerce table.shop_table_responsive tr td.order-actions,
	.woocommerce-page table.shop_table_responsive tr td.order-actions {
	    text-align: left!important
	}

	.woocommerce table.shop_table_responsive tr td:before,
	.woocommerce-page table.shop_table_responsive tr td:before {
	    content: attr(data-title) ": ";
	    font-weight: 700;
	    float: left
	}

	.woocommerce table.shop_table_responsive tr:nth-child(2n) td,
	.woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
	    background-color: rgba(0, 0, 0, .025)
	}

	.woocommerce table.my_account_orders tr td.order-actions,
	.woocommerce-page table.my_account_orders tr td.order-actions {
	    text-align: left
	}

	.woocommerce table.my_account_orders tr td.order-actions .button,
	.woocommerce-page table.my_account_orders tr td.order-actions .button {
	    float: none;
	    margin: .125em .25em .125em 0
	}

	.woocommerce .col2-set .col-1,
	.woocommerce .col2-set .col-2,
	.woocommerce-page .col2-set .col-1,
	.woocommerce-page .col2-set .col-2 {
	    float: none;
	    width: 100%
	}

	.woocommerce ul.products li.product,
	.woocommerce-page ul.products li.product,
	.woocommerce-page[class*=columns-] ul.products li.product,
	.woocommerce[class*=columns-] ul.products li.product {
	    width: 48%;
	    float: left;
	    clear: both;
	    margin: 0 0 2.992em
	}

	.woocommerce ul.products li.product:nth-child(2n),
	.woocommerce-page ul.products li.product:nth-child(2n),
	.woocommerce-page[class*=columns-] ul.products li.product:nth-child(2n),
	.woocommerce[class*=columns-] ul.products li.product:nth-child(2n) {
	    float: right;
	    clear: none!important
	}

	.woocommerce #content div.product div.images,
	.woocommerce #content div.product div.summary,
	.woocommerce div.product div.images,
	.woocommerce div.product div.summary,
	.woocommerce-page #content div.product div.images,
	.woocommerce-page #content div.product div.summary,
	.woocommerce-page div.product div.images,
	.woocommerce-page div.product div.summary {
	    float: none;
	    width: 100%
	}

	.woocommerce #content table.cart td.actions,
	.woocommerce table.cart td.actions,
	.woocommerce-page #content table.cart td.actions,
	.woocommerce-page table.cart td.actions {
	    text-align: left
	}

	.woocommerce #content table.cart td.actions .coupon,
	.woocommerce table.cart td.actions .coupon,
	.woocommerce-page #content table.cart td.actions .coupon,
	.woocommerce-page table.cart td.actions .coupon {
	    float: none;
	    padding-bottom: .5em
	}

	.woocommerce #content table.cart td.actions .coupon:after,
	.woocommerce #content table.cart td.actions .coupon:before,
	.woocommerce table.cart td.actions .coupon:after,
	.woocommerce table.cart td.actions .coupon:before,
	.woocommerce-page #content table.cart td.actions .coupon:after,
	.woocommerce-page #content table.cart td.actions .coupon:before,
	.woocommerce-page table.cart td.actions .coupon:after,
	.woocommerce-page table.cart td.actions .coupon:before {
	    content: " ";
	    display: table
	}

	.woocommerce #content table.cart td.actions .coupon:after,
	.woocommerce table.cart td.actions .coupon:after,
	.woocommerce-page #content table.cart td.actions .coupon:after,
	.woocommerce-page table.cart td.actions .coupon:after {
	    clear: both
	}

	.woocommerce #content table.cart td.actions .coupon .button,
	.woocommerce #content table.cart td.actions .coupon .input-text,
	.woocommerce #content table.cart td.actions .coupon input,
	.woocommerce table.cart td.actions .coupon .button,
	.woocommerce table.cart td.actions .coupon .input-text,
	.woocommerce table.cart td.actions .coupon input,
	.woocommerce-page #content table.cart td.actions .coupon .button,
	.woocommerce-page #content table.cart td.actions .coupon .input-text,
	.woocommerce-page #content table.cart td.actions .coupon input,
	.woocommerce-page table.cart td.actions .coupon .button,
	.woocommerce-page table.cart td.actions .coupon .input-text,
	.woocommerce-page table.cart td.actions .coupon input {
	    width: 48%;
	    box-sizing: border-box
	}

	.woocommerce #content table.cart td.actions .coupon .button.alt,
	.woocommerce #content table.cart td.actions .coupon .input-text+.button,
	.woocommerce table.cart td.actions .coupon .button.alt,
	.woocommerce table.cart td.actions .coupon .input-text+.button,
	.woocommerce-page #content table.cart td.actions .coupon .button.alt,
	.woocommerce-page #content table.cart td.actions .coupon .input-text+.button,
	.woocommerce-page table.cart td.actions .coupon .button.alt,
	.woocommerce-page table.cart td.actions .coupon .input-text+.button {
	    float: right
	}

	.woocommerce #content table.cart td.actions .button,
	.woocommerce table.cart td.actions .button,
	.woocommerce-page #content table.cart td.actions .button,
	.woocommerce-page table.cart td.actions .button {
	    display: block;
	    width: 100%
	}

	.woocommerce .cart-collaterals .cart_totals,
	.woocommerce .cart-collaterals .cross-sells,
	.woocommerce .cart-collaterals .shipping_calculator,
	.woocommerce-page .cart-collaterals .cart_totals,
	.woocommerce-page .cart-collaterals .cross-sells,
	.woocommerce-page .cart-collaterals .shipping_calculator {
	    width: 100%;
	    float: none;
	    text-align: left
	}

	.woocommerce-page.woocommerce-checkout form.login .form-row,
	.woocommerce.woocommerce-checkout form.login .form-row {
	    width: 100%;
	    float: none
	}

	.woocommerce #payment .terms,
	.woocommerce-page #payment .terms {
	    text-align: left;
	    padding: 0
	}

	.woocommerce #payment #place_order,
	.woocommerce-page #payment #place_order {
	    float: none;
	    width: 100%;
	    box-sizing: border-box;
	    margin-bottom: 1em
	}

	.woocommerce .lost_reset_password .form-row-first,
	.woocommerce .lost_reset_password .form-row-last,
	.woocommerce-page .lost_reset_password .form-row-first,
	.woocommerce-page .lost_reset_password .form-row-last {
	    width: 100%;
	    float: none;
	    margin-right: 0
	}

	.woocommerce-account .woocommerce-MyAccount-content,
	.woocommerce-account .woocommerce-MyAccount-navigation {
	    float: none;
	    width: 100%
	}

	.single-product .twentythirteen .panel {
	    padding-left: 20px!important;
	    padding-right: 20px!important
	}

}