.nav, .nav ul, .nav li {
    list-style: none;
}

.nav ul {
    padding: 0;
    margin: 0 0 0 18px;
}

.nav {
    padding: 4px;
    margin: 0px;
}

.nav > li {
    margin: 4px 0;
}

.nav > li li {
    margin: 2px 0;
}

.nav a {
    color: #333;
    display: block;
    outline: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-decoration: none;
}

.nav li > a > span {
    float: right;
    font-size: 19px;
    font-weight: bolder;
}

.nav li > a:hover > span {
    color: #fff;
}

.nav li > a > span:after {
    content: '\25be';
}
.nav li.open > a > span:after {
    content: '\25b4';
}

.nav a:hover, .nav li.active > a {
    background-color: #5D5D5D;
    color: #f5f5f5;
}

.nav > li.active > a  {
    background-color: #4D90FE;
}

.nav li a {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 10px;
}

.nav > li > a {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 10px;
}